
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { Comment, Notice } from '@/interface/interface';
import CommentRow from '@/pages/my-page/board/comment-row.vue';
import { SOCKET_EVENTS } from '@/enum/enums';
import { Socket } from 'vue-socket.io-extended';
import CommentForm from '@/pages/my-page/board/comment-form.vue';
@Component({
  components: {
    CommentRow,
    CommentForm,
  }
})
export default class BoardComment extends Vue {
  @Prop() _id!: string;
  private isActive = true;

  private notice: Notice | null = null;
  private comments: Comment[] = [];
  private comment: string | null = null;
  private nextSeq = 0;
  private nextAvailable = true;
  private replyId: string | null = null;
  private len = 20;
  created() {
    (async () => {
      await this.load();
    })();
  }

  mounted() {
    this.$store.dispatch(`sockets/${SOCKET_EVENTS.JOIN_BOARD}`, { _id: this._id, vm: this });
  }

  beforeDestroy() {
    this.$store.dispatch(`sockets/${SOCKET_EVENTS.LEAVE_BOARD}`, { _id: this._id, vm: this });
  }

  private newComment() {
    this.$store.dispatch(`sockets/${SOCKET_EVENTS.NEW_COMMENT}`, { _id: this._id, vm: this });
  }

  private async load() {
    if (!this.active) return;
    if (!this.nextAvailable) return;
    this.loading = true;
    const params = this.nextSeq ? { seq: this.nextSeq } : {};
    const { data, result, message } = await this.axios({
      url: this.url,
      method: 'GET',
      params,
    });
    if (result) {
      const { notice, comments, limit, hasNext } = data as { notice: Notice, comments: Comment[], limit: number, hasNext: boolean };
      this.len = limit;
      this.notice = notice;
      const { commentActive } = notice;
      this.isActive = commentActive;
      this.nextAvailable = hasNext;
      if (this.nextSeq) {
        this.comments.push(...comments);
        const index = this.comments.findIndex(({ seq }) => this.nextSeq === seq);
        if (index >= 0) this.focusEl(index);
      } else {
        this.comments = comments;
        this.focusBottom();
      }
    }
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    this.loading = false;
  }

  next(seq: number): void {
    if (this.loading) return;
    this.nextSeq = seq;
    this.load();
  }

  nextButton() {
    const last = this.comments.length ? this.comments[this.comments.length - 1] : null;
    if (last) {
      const { seq } = last;
      this.next(seq);
    }
  }

  private async save({ notice, comments, limit, lastSeq, created }: { notice: Notice, comments: Comment[], limit: number, lastSeq: number, created?: true }) {
    this.len = limit;
    this.nextAvailable = true;
    this.notice = notice;
    const { commentActive } = notice;
    if (created) {
      this.comments.push(...comments);
    } else {
      const index = this.comments.findIndex(({ seq }) => seq === lastSeq);
      const filtered = this.comments.filter(({ seq }) => seq === lastSeq);
      console.log(index, filtered.length, comments);
      this.comments.splice(index, filtered.length, ...comments);
    }
    this.isActive = commentActive;
    this.comment = null;
    this.nextSeq = 0;
    this.newComment();
    this.focusBottom();
  }

  @Emit('remove')
  private async remove({ notice, comment, limit, idx }: { notice: Notice, comment: Comment, limit: number, idx: number }) {
    if (this.comments[idx]) {
      // this.comments.splice(idx, 1, comment);
      this.comments.splice(idx, 1);
      this.notice = notice;
      this.len = limit;
    }
  }

  private async reply(_id: string) {
    this.replyId = _id;
  }

  private focusEl(index: number) {
    this.$nextTick(() => {
      const ref = this.$refs.comments as Vue[];
      if (ref && ref[index]) {
        const { $el } = ref[index];
        if ($el) {
          const parent = $el.parentElement;
          if (parent && parent.scrollHeight) parent.scrollTop = parent.scrollHeight;
        }
      }
    });
  }

  private focusBottom(): void {
    this.$nextTick(() => {
      const refs = this.$refs.comments as Vue[];
      if (refs && refs[refs.length - 1]) {
        const ref = refs[refs.length - 1];
        const { $el } = ref;
        if ($el) {
          const parent = $el.parentElement;
          if (parent && parent.scrollHeight) parent.scrollTop = parent.scrollHeight;
          // $el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        }
        //
      }
    });
  }

  @Socket(SOCKET_EVENTS.NEW_COMMENT)
  newCommentNoty() {
    this.successMessage(`New Comment.`);
  }

  get userId() {
    return this.$store.getters.userID;
  }

  get url() {
    return `/notice/comment/${this._id}`;
  }

  get active() {
    if (this._id === 'new') return false;
    return true;
  }
}
