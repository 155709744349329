
import { Component, Vue } from 'vue-property-decorator';
import { Notice } from '@/interface/interface';
import { NOTICE_TYPE } from '@/enum/enums';
@Component({})
export default class BoardMixin extends Vue {
  public navigationPage?: number;
  public deleteFlag = false;
  public async getContent(_id: string): Promise<Notice | null> {
    if (_id === 'new') {
      const { data, result } = await this.axios({
        url: `/notice/${_id}`
      });
      const { _id: newId } = data;
      return {
        index: 0,
        content: '',
        order: 0,
        read: false,
        readCount: 0,
        readDate: null,
        regDate: new Date().toISOString(),
        regUserId: this.userId,
        sendUserId: [],
        type: NOTICE_TYPE.PANEL_INQUIRY,
        title: '',
        commentCount: 0,
        commentActive: true,
        answered: false,
        _id: newId,
        replaceContent: '',
        targetGroup: [],
        new: true,
        uploadedFiles: [],
        uploadedFilesCount: 0,
        typeLabel: '',
      };
    }
    const { data, result } = await this.axios({
      url: `/notice/${_id}`
    });
    if (data && result) {
      const { doc } = data as { doc: Notice, urls: { idx: number, src: string }[] };
      return doc;
    }
    return null;
  }

  get dummyDoc() {
    return {
      type: NOTICE_TYPE.PANEL_INQUIRY,
      title: '',
      regDate: new Date().toISOString(),
      regUserId: this.userId,
      commentCount: 0,
      readCount: 0,
      commentActive: true,
    };
  }

  public async getGroups() {
    const { data, result } = await this.axios({
      url: `/notice/groups`
    });
    return result ? data : [];
  }

  public async getTypes(_id: string): Promise<{ text: string; value: NOTICE_TYPE }[]> {
    const { data, result } = await this.axios.get<{ text: string; value: NOTICE_TYPE }[]>(`/notice/types/${_id}`);
    if (result) return data;
    return [];
  }
  public async remove(_id: string) {
    const { data, result, message } = await this.axios({
      url: `/notice/${_id}`,
      method: 'DELETE',
    });
    this.deleteFlag = false;
    if (result) {
      if (message) this.successMessage(message.join(''));
      await this.$router.replace({ path: `/my-page/board`, query: this.queries })
    }
  }

  public async like(_id: string): Promise<Notice | null> {
    const { data, result } = await this.axios({
      url: `/notice/like/${_id}`,
      method: 'POST',
    });
    if (result) {
      const { doc } = data as { doc: Notice };
      return doc;
    }
    return null;
  }

  public get userId(): string {
    return this.$store.getters.userID;
  }

  public get noticeTypesENum() {
    return NOTICE_TYPE;
  }


  get queries() {
    const { page, search, searchValue } = this.$route.query;
    return {
      page: isNaN(+page) ? '1' : page, search, searchValue, timeStamp: new Date().getTime().toString(),
    }
  }

}

