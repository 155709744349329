
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FileInfo } from '@/interface/interface';

@Component({})
export default class FileUploaderRow extends Vue {
  @Prop() file!: FileInfo;
  @Prop() editable?: boolean;
  constructor() {
    super();
  }
  private async removeFile(id: string) {
    this.proc(true);
    const { data, result, message } = await this.axios({
      url: `/upload/delete/${encodeURIComponent(this.fileId)}`,
      method: 'DELETE',
    });
    if (result) {
      this.$emit('removeFile', this.fileId);
    }
    this.proc(false);
  }

  get fileId(): string {
    return this.file.key;
  }

  get link(): string {
    return this.file.location;
  }

  get mimetype(): string {
    return this.file.mimetype;
  }

  get filename(): string {
    return this.file.originalname;
  }

  get fileSize(): string {
    return `${(this.file.size / (1024 ** 2)).digit()} MB`;
  }

  get uploadDate(): string {
    return this.file.uploadDate.date();
  }
}
