
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FileInfo } from '@/interface/interface';
import FileUploaderRow from '@/components/file-uploader-row.vue';

@Component({
  components: {
    FileUploaderRow
  }
})
export default class Uploader extends Vue {
  @Prop() private uploadedFiles!: FileInfo[];
  @Prop() private editable!: boolean;
  private selectedFiles: File[] = [];
  private confirm = false;
  private uploadSize = 0;
  private uploading = false;
  private progress = 0;
  private max = 0;
  private removing = false;

  constructor() {
    super();
    this.checkSize();
  }
  private async checkSize() {
    const { data, result, message } = await this.axios({
      url: `/upload/size`
    });
    if (result) {
      const { uploadSize } = data as { uploadSize: number };
      if (uploadSize) this.uploadSize = uploadSize;
    }
  }
  private change(file: File) {
    this.confirm = !!this.selectedFiles.length;
    if (this.uploadSize < this.fileSize) {
      this.confirm = false;
      this.errorMessage(`MAX SIZE ${this.uploadSize}`);
      return;
    }
  }
  private async upload() {
    if (!this.selectedFiles.length) return;
    this.uploading = true;
    const formData = new FormData();
    for (const file of this.selectedFiles) {
      formData.append('file', file);
    }
    const { data, result } = await this.axios({
      url: `/upload`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 1e5,
      data: formData,
      onUploadProgress: evt => {
        const { loaded, total } = evt;
        this.progress = loaded;
        this.max = total;
        if (loaded === total) this.uploading = false;
      }
    });
    if (result) {
      const { files } = data as { files: FileInfo[] };
      this.selectedFiles = [];
      this.$emit('uploaded', files);
    }
    this.uploading = false;
  }
  private removeFile(id: string) {
    this.$emit('removeFile', id);
  }

  private cancel() {
    this.selectedFiles = [];
  }

  get fileSize() : number {
    return this.selectedFiles.reduce((prev, cur) => prev + cur.size, 0);
  }

  get fileCount(): number {
    return this.selectedFiles.length;
  }

  get fileName(): string | null {
    return this.selectedFiles.map(file => file.name).join(',');
  }
}
