
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Notice } from '@/interface/interface';
import { NOTICE_TYPE } from '@/enum/enums';
@Component({})
export default class BorderHeader extends Vue {
  @Prop() private doc?: Notice;
  @Prop() private noEdit?: boolean;
  @Prop() private isNew?: boolean;

  remove() {
    this.$emit('remove', this._id);
  }

  get editRouter() {
    return { path: `/my-page/board/${this._id}/edit` }
  }

  get replyRouter() {
    return { path: `/my-page/board/${this._id}/reply` }
  }

  get _id(): string | null {
    return this.doc ? this.doc._id : null;
  }

  get type(): string | null {
    return this.doc ? this.doc.typeLabel : null;
  }

  get userId() {
    return this.$store.getters.userID;
  }

  get owner(): boolean {
    return this.doc ? this.doc.regUserId === this.userId : false;
  }

  get noticeType(): string  {
    return this.type ? `[${this.type}]` : '[NONE]';
  }

  get title(): string | null {
    return this.doc ? this.doc.title : null;
  }
  get regUser(): string | null {
    return this.doc ? (this.doc.regUserName || this.doc.regUserId) : null;
  }
  get regDate(): string {
    return this.doc ? this.doc.regDate.date() : new Date().toLocaleString();
  }
  get readCount(): string {
    return this.doc ? new Intl.NumberFormat().format(this.doc.readCount) : '0';
  }
  get commentCount(): string {
    return this.doc ? new Intl.NumberFormat().format(this.doc.commentCount) : '0';
  }
  get answered(): boolean {
    return this.doc ? this.doc.answered : false;
  }
  get noticeTypesENum() {
    return NOTICE_TYPE;
  }
  get isReply(): boolean {
    return this.$route.path.endsWith('reply');
  }
  get targetGroup(): string {
    return this.doc ? this.doc.targetGroup.length ? this.doc.targetGroup.join(',') : 'All' : '';
  }

  get menuExists(): boolean {
    if (this.isNew) return false;
    if (this.noEdit) return false;
    return (this.owner && !this.noEdit) || (this.owner)  || (this.type === this.noticeTypesENum.PANEL_INQUIRY && !this.answered && !this.owner && !this.isReply);
  }
  get specificUsers() : string[] {
    return this.doc ? this.doc.sendUserId : [];
  }
}

