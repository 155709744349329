
import { Component, Prop, Vue } from 'vue-property-decorator';
import CommentForm from '@/pages/my-page/board/comment-form.vue';
import { Comment, Notice } from '@/interface/interface';
@Component({
  components: {
    CommentForm
  }
})
export default class CommentRow extends Vue {
  @Prop() seq!: number;
  @Prop() order!: number;
  @Prop() noticeId!: string;
  @Prop() _id!: string;
  @Prop() content!: string;
  @Prop() regUserId!: string;
  @Prop() regUserName?: string;
  @Prop() targetId?: string;
  @Prop() regDateString!: string;
  @Prop() isEnd!: boolean;
  @Prop() idx!: number;
  @Prop() length?: number;
  @Prop() depth!: number;
  @Prop() isDelete!: boolean;
  @Prop() lastItem!: Comment;
  private replyFlag = false;
  private restCols = 3;


  private handleIntersection(isIntersect: boolean, el: Element, options: any) {
    // console.log(isIntersect, el, options);
    if (isIntersect && this.isEnd) {
      // this.$emit('next', this.seq);
    }
  }
  private save({ notice, comments, limit, lastSeq, created }: { notice: Notice, comments: Comment[], limit: number, lastSeq: number, created?: true }) {
    this.loading = true;
    this.$emit('save', { notice, comments, limit, lastSeq, created });
    this.replyFlag = false;
    this.loading = false;
  }
  private async remove() {
    this.loading = true;
    const { data, result, message } = await this.axios({
      url: this.url,
      method: 'DELETE',
      data: {
        _id: this._id
      }
    });
    if (result) {
      const { notice, comment, limit } = data as { notice: Notice, comment: Comment, limit: number };
      this.$emit('remove', { notice, comment, limit, idx: this.idx });
    }
    this.loading = false;
  }

  private reply(){
    this.replyFlag = !this.replyFlag;
    // this.$emit('reply', this._id);
  }

  get url() {
    return `/notice/comment/${this.noticeId}`;
  }

  get userId() {
    return this.$store.getters.userID;
  }

  get shift() {
    return 12 - this.restCols - this.depth;
  }

  get shiftContent() {
    return 12 - this.depth;
  }

  get regDate() {
    return this.regDateString.date();
  }

  get lastSeq() {
    return this.lastItem.seq;
  }
}


