
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Comment, Notice } from '@/interface/interface';
@Component({})
export default class CommentForm extends Vue {
  @Prop() isActive!: boolean;
  @Prop() _id!: string;
  @Prop() seq?: string;
  @Prop() depth!: number;
  @Prop() idx!: number;
  @Prop() originId?: string;
  @Prop() placeHolder?: string;
  @Prop() lastItem?: Comment;
  private comment: string | null = null;


  mounted() {
    // if (this.form) this.form.focus();
  }

  async save(): Promise<void> {
    this.proc(true);
    const params = this.originId ? {
      content: this.comment,
      originId: this.originId,
      depth: this.depth,
      seq: this.seq || 1,
    } : {
      content: this.comment,
      seq: this.seq || 1,
    };
    const { data, result, message } = await this.axios({
      url: this.url,
      method: this.originId ? 'PUT' : 'POST',
      data: params
    });
    if (result) {
      this.comment = null;
      const { notice, comments, limit, lastSeq, created } = data as { notice: Notice, comments: Comment[], limit: number; lastSeq: number; created?: true }
      this.$emit('save', { notice, comments, limit, lastSeq, created });
    } else {
      this.validationMessages(message)
    }
    // await new Promise((resolve) => setTimeout(resolve, 1500));
    this.proc(false);
  }

  get url() {
    return `/notice/comment/${this._id}`;
  }

  get form() {
    const ref = this.$refs.comment as Vue | undefined;
    return ref ? ref.$el as HTMLInputElement : null;
  }
}
